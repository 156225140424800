<template>
  <div class="">
    <nav class="navbar navbar-custom level px-6">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <a :href="'/create-company?order=' + $route.query.order">
            <img src="/images/icons/arrow_back.svg" alt="" />
          </a>
        </div>
        <div class="level-item">
          <h2 class="title">Receipt</h2>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <b-button
          @click="generatePDF()"
          type="is-dark"
          label="Print"
          icon-left="printer"
        />
      </div>
    </nav>

    <div class="container">
      <div id="printHtml " class="box-pdf">
        <!-- Isi konten di sini -->
        <div class="is-flex is-justify-content-space-between">
          <div>
            <img class="rise-logo" src="/images/rise_logo_red.png" alt="" />
          </div>
          <div class="has-text-right">
            <p class="invoice-text">INVOICE</p>
            <p class="invoice-code">
              {{ convertInvoiceCode(datas.invoice_code) }}
            </p>
          </div>
        </div>
        <div
          class="is-flex is-justify-content-space-between print-content mt-5"
        >
          <div>
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between"
            >
              <div>
                <p class="order-header">Order Detail</p>
              </div>
              <div>
                <p class="subscribe-header">Subscribe Rise</p>
                <div class="is-flex is-flex-direction-row">
                  <div>
                    <ul v-for="(val, id) in subscribeKey" :key="id">
                      <li>{{ val }} :</li>
                    </ul>
                  </div>
                  <div>
                    <ul v-for="(val, id) in datas.items" :key="id">
                      <li>{{ val.name }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns mt-3">
              <div class="column is-4">
                <ul class="mt-2" v-for="(val, id) in orderKey" :key="id">
                  <li class="order-detail">{{ val }}</li>
                </ul>
              </div>
              <div class="column is-12">
                <ul class="mt-2">
                  <li class="order-detail">: {{ datas.code }}</li>
                  <li class="order-detail pt-2">: {{ datas.buyer_name }}</li>
                  <li class="order-detail pt-2">: {{ datas.payment_date }}</li>
                  <li class="order-detail pt-2">: {{ datas.billing_date }}</li>
                  <li class="order-detail pt-2">
                    : {{ datas.payment_method }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="ml-3">
          <p class="subscribe-header">Subscribe Rise</p>
          <div class="is-flex is-flex-direction-row">
            <div>
              <ul v-for="(val, id) in subscribeKey" :key="id">
                <li>{{ val }} :</li>
              </ul>
            </div>
            <div>
              <ul v-for="(val, id) in datas.items" :key="id">
                <li>{{ val.name }}</li>
              </ul>
            </div>
          </div>
        </div> -->
        </div>

        <div class="is-flex is-justify-content-end mt-6">
          <div class="mr-6 is-flex is-flex-direction-row total-content">
            <div>
              <ul v-for="(val, id) in datas.items" :key="id">
                <li class="total-text">Total</li>
                <li class="total-text">Discount {{ val.discount_amount }}%</li>
                <li class="total-text" v-if="voucherAmount">
                  Voucher {{ datas.vouchers[0].discount }}%
                </li>
                <li class="total-text">Tax {{ val.tax_percentage }}%</li>
              </ul>
            </div>
            <div class="ml-6">
              <ul v-for="(val, id) in datas.items" :key="id">
                <li class="total-text">
                  {{ formatCurrency(val.price_package) }}
                </li>
                <li class="total-text">
                  - {{ formatCurrency(val.show_discount) }}
                </li>
                <li class="total-text" v-if="voucherAmount">
                  - {{ formatCurrency(voucherAmount) }}
                </li>
                <li class="total-text">+ {{ formatCurrency(countTax()) }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="is-flex is-justify-content-end mt-4">
          <div class="mr-6 is-flex is-flex-direction-row total-content">
            <div>
              <ul v-for="(val, id) in subtotalKey" :key="id">
                <li class="total-text">{{ val }}</li>
              </ul>
            </div>
            <div class="subtotal-left">
              <ul>
                <li class="total-text">
                  {{ formatCurrency(countSubtotal()) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="has-text-centered footer-pdf">
          <p>PT. Roketin Kreatif Teknologi</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import axios from 'axios'
export default {
  data() {
    return {
      datas: null,
      orderKey: [
        'Order ID',
        'Buyer',
        'Date Payment',
        'Billing Date Issue',
        'Method Payment',
      ],
      orderValue: [
        '#0001',
        'Johndoe@mail.com',
        '1 January 2023',
        '1 February 2023',
        'Virtual Account',
      ],
      subscribeKey: ['Package'],
      subscribeVal: ['Company'],
      totalKey: ['Total', 'Discount 20%', 'Tax 11%'],
      totalVal: ['Rp. 400.000', '- Rp. 20.000', '+ Rp. 5.000'],
      subtotalKey: ['Grand Total'],
      subtotalVal: ['Rp. 385.000'],
      voucherAmount: null,
      tax: 0,
      discountedPrice: 0,
      priceAfterVoucher: 0,
    }
  },
  computed: {
    orderId() {
      const orderId = this.datas.code.split('-')
      return `${orderId[0]}-${orderId[4]}`
    },
  },
  mounted() {
    this.getReceipt()
  },
  methods: {
    formatCurrency(value) {
      return `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },
    convertInvoiceCode(code) {
      const invoiceCode = code.split('/')
      const orderId = invoiceCode[1].split('-').pop()
      return `${invoiceCode[0]}/${orderId}/${invoiceCode[2]}/${invoiceCode[3]}`
    },
    async getReceipt() {
      try {
        const code = this.$route.query.order
        const response = await axios.get(
          `/api/space-roketin/payment/v1/payment/${code}`
        )
        this.datas = response.data.data
        this.voucherAmount =
          (this.datas.items[0].price_package -
            this.datas.items[0].show_discount) /
          this.datas?.vouchers[0]?.discount
        this.tax = this.datas.total_tax
      } catch (error) {
        console.error(error)
      }
    },
    countTax() {
      this.discountedPrice =
        this.datas?.items[0]?.price_package -
        this.datas?.items[0]?.show_discount
      this.priceAfterVoucher =
        this.discountedPrice - (this.voucherAmount ? this.voucherAmount : 0)
      this.tax =
        this.priceAfterVoucher * (this.datas?.items[0]?.tax_percentage / 100)

      return this.tax
    },
    countSubtotal() {
      return (
        this.datas.items[0].price_package -
        this.datas.items[0].show_discount -
        (this.voucherAmount ? this.voucherAmount : 0) +
        this.tax
      )
    },
    async generatePDF() {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a5',
      })

      doc.setFont('helvetica', 'bold')
      doc.addImage('/images/rise_logo_red.png', 'PNG', 10, 10, 24, 8.5)
      doc.setFontSize(13)

      doc.text('INVOICE', 120, 15)

      doc.setFontSize(12)
      doc.setFont('helvetica', 'normal')
      doc.text(this.convertInvoiceCode(this.datas.invoice_code), 72, 20)

      doc.setFontSize(12)
      doc.setFont('helvetica', 'bold')
      doc.text('Order Detail', 10, 35)

      doc.setFontSize(12)
      doc.setFont('helvetica', 'normal')
      doc.text('Order ID', 10, 50)
      doc.text('Buyer', 10, 60)
      doc.text('Date Payment', 10, 70)
      doc.text('Billing Date Issue', 10, 80)
      doc.text('Method Payment', 10, 90)

      doc.text(
        this.datas && this.datas.code ? ': ' + this.datas.code : ': -',
        55,
        50
      )
      doc.text(
        this.datas && this.datas.buyer_name
          ? ': ' + this.datas.buyer_name
          : ': -',
        55,
        60
      )
      doc.text(
        this.datas && this.datas.payment_date
          ? ': ' + this.datas.payment_date
          : ': -',
        55,
        70
      )
      doc.text(
        this.datas && this.datas.billing_date
          ? ': ' + this.datas.billing_date
          : ': -',
        55,
        80
      )
      doc.text(
        this.datas && this.datas.payment_method
          ? ': ' + this.datas.payment_method
          : ': -',
        55,
        90
      )

      doc.text('Subscribe Rise', 85, 35)
      doc.text(`Package : ${this.datas.items[0].name || '-'}`, 85, 40)

      doc.setFont('helvetica', 'bold')
      doc.text('Total', 70, 110)
      doc.text(`Discount ${this.datas.items[0].discount_amount}%`, 70, 120)

      if (this.datas.vouchers[0] && this.datas.vouchers[0].discount) {
        doc.text(`Voucher ${this.datas.vouchers[0].discount}%`, 70, 130)
        doc.text(`Tax ${this.datas.items[0].tax_percentage}%`, 70, 140)

        doc.setFont('helvetica', 'bold')
        doc.text(
          `${this.formatCurrency(this.datas.items[0].price_package)}`,
          100,
          110
        )
        doc.text(
          `- ${this.formatCurrency(this.datas.items[0].show_discount)}`,
          100,
          120
        )
        doc.text(`- ${this.formatCurrency(this.voucherAmount)}`, 100, 130)
        doc.text(`+ ${this.formatCurrency(this.tax)}`, 100, 140)

        doc.text('Grand Total', 70, 155)
        doc.text(`${this.formatCurrency(this.countSubtotal())}`, 100, 155)
      } else {
        doc.text(`Tax ${this.datas.items[0].tax_percentage}%`, 70, 130)

        doc.setFont('helvetica', 'bold')
        doc.text(
          `${this.formatCurrency(this.datas.items[0].price_package)}`,
          100,
          110
        )
        doc.text(
          `- ${this.formatCurrency(this.datas.items[0].show_discount)}`,
          100,
          120
        )
        doc.text(`+ ${this.formatCurrency(this.tax)}`, 100, 130)

        doc.text('Grand Total', 70, 145)
        doc.text(`${this.formatCurrency(this.countSubtotal())}`, 100, 145)
      }

      doc.text('PT. Roketin Kreatif Teknologi', 45, 180)

      doc.save('rise.pdf')
    },
  },
}
</script>

<style></style>
